import React, { Component } from "react";
import { connect } from "react-redux";
import { initializeBotSession, respond } from "../actions";
import { v4 as uuidv4 } from "uuid";
import "./Chatbot.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import tr from "date-fns/locale/tr";
import "react-datepicker/dist/react-datepicker.css";
import { PaperPlane } from "../assets/icons";
class Chatbot extends Component {
  constructor(props) {
    super(props);
    registerLocale("tr", tr);
    setDefaultLocale("tr");
    this.sRef = React.createRef();
    this.state = { input: "", dateInput: "", carParts: [] };
    this.onInputChange = this.onInputChange.bind(this);
    this.onDateSelected = this.onDateSelected.bind(this);
    this.onDateSubmitted = this.onDateSubmitted.bind(this);
    this.onCarPartSelected = this.onCarPartSelected.bind(this);
    this.focusToInput = this.focusToInput.bind(this);
  }

  componentDidMount() {
    const { messages } = this.props;

    if (messages.length === 0) {
      const id = uuidv4();
      const customerId = uuidv4();
      const conversationId = uuidv4();
      const recipientId = uuidv4();
      this.props.initializeSession(id, customerId, conversationId, recipientId);
    }

    this.scrollToBottom();
  }

  componentDidUpdate() {
    this.scrollToBottom();
    setTimeout(() => {
      this.focusToInput();
    }, 100);
  }

  focusToInput() {
    if (this.textInput) {
      this.textInput.focus();
    }
  }

  scrollToBottom() {
    this.messagesEnd.scrollIntoView({ behavior: "smooth" });
  }

  renderMessage(message) {
    if (message.type === "message") {
      const splittedMessage = message && message.text.split("\n\n");

      return (
        <div className="answer bot">
          <div className="header">
            <div className="avatar bot">
              <img alt="AXA Bot" src="https://bot.pisano.com.tr/img/bot.png" />
            </div>
            AXA Bot
          </div>
          {splittedMessage.map((text) => (
            <div
              className="bubble"
              key={text.hashCode()}
              dangerouslySetInnerHTML={{ __html: text }}
            >
              {/* <ReactMarkdown key={text.hashCode()}>{text}</ReactMarkdown> */}
            </div>
          ))}
          <div
            style={{ float: "left", clear: "both" }}
            ref={(el) => {
              this.messagesEnd = el;
            }}
          ></div>
        </div>
      );
    }

    return (
      <div className="answer user">
        <div className="header user">
          Siz
          <div className="avatar user">
            <FontAwesomeIcon icon={faUser} size="1x" />
          </div>
        </div>
        <div className="bubble user">{message.text}</div>
      </div>
    );
  }
  resetInput = () => {
    this.setState({
      input: "",
    });
  };

  onEnterPress = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      const { respond, session } = this.props;

      e.preventDefault();
      respond(
        e.target.value,
        session.id,
        session.customerId,
        session.conversationId,
        session.recipientId,
        e.target.value
      );
      this.resetInput();
    }
  };

  onSendButtonPress = () => {
    const { respond, session } = this.props;

    respond(
      this.state.input,
      session.id,
      session.customerId,
      session.conversationId,
      session.recipientId,
      this.state.input
    );
    this.resetInput();
  };

  getHour = (date) => {
    let hour = date.getHours().toString();
    let minute = date.getMinutes().toString();

    hour = hour.length > 1 ? hour : "0" + hour;
    minute = minute.length > 1 ? minute : "0" + minute;
    return hour + ":" + minute;
  };

  formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();

    let month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : "0" + month;

    let day = date.getDate().toString();
    day = day.length > 1 ? day : "0" + day;

    return day + "." + month + "." + year;
  };

  renderCarParts(lastMessage) {
    const { respond, session } = this.props;
    const { carParts } = this.state;

    const options = lastMessage.attachments[0].content.buttons;

    return (
      <>
        <div className="chatbot-buttons car">
          {options.map((button) => (
            <button
              onClick={() => this.onCarPartSelected(button.text.trim())}
              key={button.text}
              className={
                carParts.includes(button.text.trim()) ? "active" : "passive"
              }
            >
              <img alt={button.text} src={button.value.trim()} />
            </button>
          ))}
        </div>
        <button
          className="float-right car-parts-next"
          onClick={() =>
            respond(
              carParts.join(","),
              session.id,
              session.customerId,
              session.conversationId,
              session.recipientId,
              carParts.join(",")
            )
          }
          disabled={carParts.length > 0 ? "" : "disabled"}
        >
          Devam
        </button>
      </>
    );
  }

  onDateSelected = (date) => {
    this.setState({
      dateInput: this.formatDate(date),
    });
  };

  onDateSubmitted = () => {
    const { respond, session } = this.props;
    const { dateInput } = this.state;

    respond(
      dateInput,
      session.id,
      session.customerId,
      session.conversationId,
      session.recipientId,
      dateInput
    );
    this.setState({
      dateInput: "",
    });
  };

  renderDateInput(lastMessage) {
    const { dateInput } = this.state;

    const CustomPicker = React.forwardRef(({ value, onClick }, ref) => (
      <button className="custom-date-input" onClick={onClick} ref={ref}>
        {dateInput ? dateInput : "Lütfen tarih seçiniz"}
      </button>
    ));

    let maxDate = new Date();
    let minDate = new Date("1900-01-01");

    if (
      lastMessage.attachments[0].content.buttons[0].value &&
      lastMessage.attachments[0].content.buttons[0].value.length > 0
    ) {
      const splittedRange =
        lastMessage.attachments[0].content.buttons[0].value.split("|");
      maxDate = new Date(splittedRange[1]);
      minDate = new Date(splittedRange[0]);
    }

    return (
      <div className="chatbot-buttons datepicker">
        <DatePicker
          locale="tr"
          onChange={(date) => this.onDateSelected(date)}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          customInput={<CustomPicker />}
          dateformat="dd.MM.yyyy"
          maxDate={maxDate}
          minDate={minDate}
        />
        <button
          className="float-right"
          onClick={() => this.onDateSubmitted()}
          disabled={dateInput.length > 0 ? "" : "disabled"}
        >
          Devam
        </button>
      </div>
    );
  }

  renderHourInput(lastMessage) {
    const { respond, session } = this.props;

    const CustomPicker = React.forwardRef(({ value, onClick }, ref) => (
      <button className="example-custom-input" onClick={onClick} ref={ref}>
        {value ? value : "Lütfen saat seçiniz"}
      </button>
    ));

    return (
      <div className="chatbot-buttons datepicker">
        <DatePicker
          locale="tr"
          onChange={(date) =>
            respond(
              this.getHour(date),
              session.id,
              session.customerId,
              session.conversationId,
              session.recipientId,
              this.getHour(date)
            )
          }
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={5}
          timeCaption="Saat"
          dateFormat="HH:mm"
          customInput={<CustomPicker />}
        />
      </div>
    );
  }

  getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  handleFileInputChange = (e) => {
    const { session, respond } = this.props;

    const file = e.target.files[0];
    const fileSize = file.size / 1024 / 1024;

    if (fileSize > 20) {
      alert("Yüklediğiniz dosya boyutu 20 MB'den küçük olmalıdır");
      e.target.value = null;
      return;
    }

    this.getBase64(file)
      .then((result) => {
        respond(
          result,
          session.id,
          session.customerId,
          session.conversationId,
          session.recipientId,
          file.name,
          false
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  renderInlineInput(lastMessage) {
    const { respond, session } = this.props;

    const options = lastMessage.attachments[0].content.buttons;

    if (
      lastMessage.attachments[0].content.subtitle === "pickList" &&
      lastMessage.attachments[0].content.buttons[0].displayText.indexOf(
        "Bu adımı"
      ) < 0
    ) {
      return this.renderCarParts(lastMessage);
    } else if (
      lastMessage.attachments[0].content.buttons[0].type === "datepicker"
    ) {
      return this.renderDateInput(lastMessage);
    } else if (
      lastMessage.attachments[0].content.buttons[0].type === "hourpicker"
    ) {
      return this.renderHourInput(lastMessage);
    }

    const firstButton = lastMessage.attachments[0].content.buttons[0];
    const shouldDisplaySelect =
      firstButton.type !== "imBack" && firstButton.type !== "filepicker";
    const shouldDisplayFileUpload = firstButton.type === "filepicker";

    if (shouldDisplaySelect) {
      const selectOptions = firstButton.displayText.split("|");
      const selectValues = firstButton.value.split("|");

      return (
        <div className="chatbot-buttons">
          <select
            className="pisano-select"
            defaultValue="DEFAULT"
            onChange={(e) =>
              respond(
                selectValues[e.target.value],
                session.id,
                session.customerId,
                session.conversationId,
                session.recipientId,
                selectOptions[e.target.value]
              )
            }
          >
            <option hidden disabled selected value="DEFAULT">
              {firstButton.text}
            </option>
            {selectOptions.map((o, index) => (
              <option key={selectValues[index]} value={index}>
                {o}
              </option>
            ))}
          </select>
        </div>
      );
    } else if (shouldDisplayFileUpload) {
      return (
        <div className="chatbot-buttons file-upload-container">
          <label className="file-upload" for="upload-photo">
            Fotoğraf seçin
          </label>
          <input
            type="file"
            name="file"
            accept="image/*"
            id="upload-photo"
            onChange={this.handleFileInputChange}
          />
          <i>* Yükleyeceğiniz dosya boyutu 20 MB'den küçük olmalıdır.</i>
        </div>
      );
    } else {
      return (
        <div className="chatbot-buttons">
          {options.map((button) => (
            <button
              onClick={() =>
                respond(
                  button.value,
                  session.id,
                  session.customerId,
                  session.conversationId,
                  session.recipientId,
                  button.title
                )
              }
              key={button.value}
            >
              {button.title}
            </button>
          ))}
        </div>
      );
    }
  }

  onInputChange(event) {
    this.setState({ input: event.target.value });
  }

  onCarPartSelected(partName) {
    let newCarParts = this.state.carParts;

    const i = newCarParts.indexOf(partName);

    if (i > -1) {
      newCarParts.splice(i, 1);
    } else {
      newCarParts.push(partName);
    }

    this.setState({ carParts: newCarParts });
  }

  renderInput(enabled = false) {
    const { session } = this.props;
    if (session.archived) {
      return (
        <div className="chatbot-input-container archived">
          <p>Bot görüşmesi sona erdi.</p>
        </div>
      );
    } else {
      return (
        <div className="chatbot-input-container">
          <textarea
            ref={(input) => {
              this.textInput = input;
            }}
            value={this.state.input}
            placeholder={
              enabled
                ? "Lütfen cevaplayınız"
                : "Lütfen yukarıdaki seçeneklerden seçim yapınız"
            }
            disabled={enabled ? "" : "disabled"}
            onChange={this.onInputChange}
            onKeyDown={this.onEnterPress}
          />
          <button
            disabled={this.state.input ? false : true}
            onClick={this.onSendButtonPress}
            className="chatbot-send-btn"
          >
            <PaperPlane />
          </button>
        </div>
      );
    }
  }

  render() {
    const { messages } = this.props;
    const loading = !messages.length > 0;
    const lastMessage = messages.slice(-1)[0];

    const shouldRenderInlineInput =
      lastMessage &&
      lastMessage.type === "message" &&
      lastMessage.attachments &&
      lastMessage.attachments[0] &&
      lastMessage.attachments[0].content &&
      lastMessage.attachments[0].content.buttons &&
      lastMessage.attachments[0].content.buttons.length > 0;

    return (
      <div className="chatbot-container">
        <div
          className="chatbot-content"
          ref={(el) => {
            this.el = el;
          }}
        >
          <div className="chatbot-messages">
            {loading
              ? this.renderMessage({ type: "message", text: "..." })
              : messages.map((message, index) => (
                  <div key={index}>{this.renderMessage(message)}</div>
                ))}
            {shouldRenderInlineInput && this.renderInlineInput(lastMessage)}
          </div>
          <div ref={this.sRef}></div>
          {!loading &&
            this.renderInput(
              lastMessage &&
                lastMessage.type === "message" &&
                lastMessage.attachments.length === 0
            )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    messages: state.messages,
    session: state.session,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initializeSession: (id, customerId, conversationId, recipientId) =>
      dispatch(
        initializeBotSession(id, customerId, conversationId, recipientId)
      ),
    respond: (
      value,
      id,
      customerId,
      conversationId,
      recipientId,
      text,
      pushToResponses
    ) =>
      dispatch(
        respond(
          value,
          id,
          customerId,
          conversationId,
          recipientId,
          text,
          pushToResponses
        )
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Chatbot);
