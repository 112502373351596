import React, { Component } from "react";
import { Router, Switch, Route } from "react-router-dom";

import { Provider } from "react-redux";
import configureStore from "./store/configureStore";
import Chatbot from "./components/chatbot";
import Chat from "./components/chat";
import history from "./history";
import AxaLogo from "./assets/images/axa.png";
import "./App.css";

const store = configureStore();

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router history={history}>
          <div className="top-bar">
            <div className="logo">
              <img alt="AXA Sigorta" src={AxaLogo} />
            </div>
          </div>
          <div className="container">
            <Switch>
              <Route exact path="/chatbot">
                <Chatbot />
              </Route>
              <Route path="/chat" render={Chat}></Route>
              <Route path="/knowledge_base">
                <KnowledgeBase />
              </Route>
            </Switch>
          </div>
        </Router>
      </Provider>
    );
  }
}

function KnowledgeBase() {
  return (
    <iframe
      title="Bilgi Bankası"
      src="https://bot.pisano.com.tr/knowledge_base/?knowledge_base_id=32f98fe1-b32e-4e68-a069-b43cb4d867c6"
    ></iframe>
  );
}

String.prototype.hashCode = function () {
  var hash = 0,
    i,
    chr;
  if (this.length === 0) return hash;
  for (i = 0; i < this.length; i++) {
    chr = this.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

export default App;
